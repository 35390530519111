import {
    HSearch,
    HSearchInput,
    HSearchHistory,
    HSearchResults,
    HSearchSubmit,
    HSearchClear,
    HSearchClose,
    HSearchMessage,
    HSearchHistoryRemove,
    HSearchHistorySelect,
    HSearchHistoryLoadMore,
    HSearchSuggestionContent,
    HSearchProductContent,
    HSearchProducerContent
} from '@shoper/phoenix_design_system';

const components = [
    HSearch,
    HSearchInput,
    HSearchHistory,
    HSearchResults,
    HSearchSubmit,
    HSearchClear,
    HSearchClose,
    HSearchMessage,
    HSearchHistoryRemove,
    HSearchHistorySelect,
    HSearchHistoryLoadMore,
    HSearchSuggestionContent,
    HSearchProductContent,
    HSearchProducerContent
];
